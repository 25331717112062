<template>
  <DefaultCard
    v-if="internalModel"
    class="d-flex flex-column justify-content-between mr-lg-4 mr-md-4 mt-4"
    ><template #card-content>
      <div class="px-4 py-2">
        <p v-if="!hidden" class="text-grey text-right" style="font-size: 12px">
          {{ createdDate }}, №{{ order.id }}
        </p>
        <b-button
          target="_blank"
          style="font-size: 18px !important"
          variant="link"
          size="lg"
          class="mt-2"
        >
          {{ order.instaname || 'Аккаунт заказчика скрыт' }}</b-button
        >
        <div v-if="order.format">
          <h3 style="font-size: 18px !important" class="fw-500 mt-4">
            {{ kindMap[order.format.kind] }}
            {{ order.format.duration ? `${order.format.duration} сек.` : '' }} за
            {{ order.format.uPrice }} ₽
          </h3>
          <p class="mt-3">
            Набор на {{ getCurrentPrMonth }}. {{ getCurrentOrdererType }}.
            {{ order.format.content }}.
          </p>
        </div>
        <h3 v-else-if="hidden" style="font-size: 18px !important" class="fw-500 mt-4">
          Формат рекламы скрыт
        </h3>
        <h3 style="font-size: 18px !important" class="fw-500 mt-4" v-else>Формат не указан</h3>
        <div v-if="!hidden">
          <h3 style="font-size: 18px !important" class="fw-500 mt-5">Тезисы</h3>
          <p class="mt-3 mb-2">{{ order.description }}</p>
        </div>
        <div class="mt-5" v-else>Тезисы скрыты</div>
        <p v-if="!hidden" class="text-grey text-right" style="font-size: 12px">
          🔥 Заявка сгорит в {{ burnDate }}
        </p>
      </div> </template
    ><template #card-footer>
      <div class="p-4 d-flex flex-row justify-content-between bb-radius">
        <template v-if="!hidden">
          <b-button
            v-if="!(order.status === orderStatuses.CANCELED)"
            class="mr-3"
            :class="{ 'ml-auto': !(order.status === orderStatuses.NEW) }"
            @click="() => $emit('refuse')"
            variant="outline-default"
            >Отказать</b-button
          >
          <b-button
            :class="{ 'ml-auto': !(order.status === orderStatuses.NEW) }"
            v-if="!(order.status === orderStatuses.ACCEPTED)"
            @click="accept"
            variant="yellow"
            >{{ order.status === orderStatuses.NEW ? 'Взять на рекламу' : 'Одобрить' }}</b-button
          >
        </template>
        <b-button
          target="_blank"
          href="https://sub.easyprbot.com/"
          v-else
          variant="yellow"
          class="w-100 py-2"
          style="white-space: break-spaces !important; line-break: anywhere !importantI"
        >
          Чтобы открыть все заявки, купите подписку</b-button
        >
      </div></template
    ></DefaultCard
  >
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import DefaultCard from '@main/components/reusable/cards/DefaultCard.vue';

export default {
  components: {
    DefaultCard,
  },
  model: {
    prop: 'order',
    event: 'input',
  },
  props: {
    order: Object,
    hidden: Boolean,
  },
  methods: {
    accept() {
      this.internalModel.status = this.orderStatuses.ACCEPTED;
      this.$emit('update-order', this.internalModel);
      this.showRefuseModal = false;
    },
  },
  computed: {
    ...mapState(['bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    getCurrentOrdererType() {
      if (this.hidden) {
        return;
      }
      const { choices } = this.options.pr_types.child.children.target;
      const values = choices.filter((val) => val.value === this.order.format.target);
      if (values.length) {
        const target = values[0].display_name;
        if (target === 'Любой') {
          return 'Личные и коммерческие блоги';
        }
        return target;
      }
      return 'Личные и коммерческие блоги';
    },
    getCurrentPrMonth() {
      if (this.hidden) {
        return;
      }
      const { choices } = this.options.pr_types.child.children.month;
      const values = choices.filter((val) => val.value === this.order.format.month);
      if (!values.length) {
        return '?';
      }
      return values[0].display_name;
    },
    burnDate() {
      if (this.hidden) {
        return;
      }
      return moment(this.order.user_confirm_at).add(3, 'd').format('HH:mm DD.MM');
    },
    createdDate() {
      if (this.hidden) {
        return;
      }
      return moment(this.order.user_confirm_at).format('HH:mm DD.MM.YY');
    },
  },
  data: () => ({
    kindMap: {
      1: 'Сторис',
      2: 'Фото-пост',
      3: 'Видео-пост',
      4: 'Сторис + пост',
    },
    orderStatuses: {
      NEW: 'NEW',
      CANCELED: 'CL',
      ACCEPTED: 'AC',
      FINISHED: 'FS',
    },
    internalModel: null,
  }),
  mounted() {
    this.internalModel = this.lodash.cloneDeep(this.order);
  },
};
</script>
